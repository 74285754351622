import { useEffect, useState } from "react";
import { inupts, preSelections, selections } from "./Selections";
import Services from "../../Providers/Services";
import { testTypes, env } from "../../Providers/TestCase";
import { usePageContext } from "../../Providers/PageContext";
import ConfirmTests from "./ConfirmTests";

// Scss
import "./testConditions.scss";

const TestConditions = (props: any) => {
  // ============================  VARIABLES  ==============================

  const [text, setText] = useState<string>("All fields"); // Set test Config
  const { getToken, fetchAllScanIds } = Services();

  const [testType, setTestType] = useState<string>("Manual Testing"); // To change test type
  const { slesctedEnv, setSelectedEnv, setPage } = usePageContext(); // To select env

  const [allData, setAllData] = useState<any>(null); // Set All data
  const [scanIds, setScanIds] = useState<string[]>([]); // scan IDs Dropdown

  const [selectedScanId, setSelectedScanId] = useState<string | null>(null); // select Scan ID
  const [dropdowns, setDropdowns] = useState<any>(null); // Set test Config
  const [submit, setSubmit] = useState<boolean>(false); // Show selected test conditions

  // ============================  FUNCTIONS  ==============================

  useEffect(() => {
    if (slesctedEnv) {
      (async () => {
        const res = (await getToken()) as any;
        if (res) {
          const data = await fetchAllScanIds();
          data && setAllData(data);
        }
      })();
    }
  }, [slesctedEnv]);

  useEffect(() => {
    if (allData) {
      let ids: string[] = [];
      Object.keys(allData).forEach(function (key) {
        try {
          if (
            allData[key].experience_config.image_count === 1 &&
            allData[key].experience_config.test_config &&
            allData[key].experience_config.test_config.test_conditions
          ) {
            ids.push(key);
          }
        } catch (error) {
          console.error(error);
        }
      });
      setScanIds(ids);
    }
  }, [allData]);

  useEffect(() => {
    if (scanIds.length > 0 && selectedScanId === null) {
      setSelectedScanId(scanIds[0]);
    }
  }, [scanIds]);

  useEffect(() => {
    if (selectedScanId) {
      const config = allData[selectedScanId].experience_config.test_config;
      const { test_conditions: _, ...newObj } = config;
      const conditions =
        allData[selectedScanId].experience_config.test_config.test_conditions;
      Object.keys(conditions).forEach(function (key) {
        newObj[key] = conditions[key];
      });
      setDropdowns(newObj);
    }
  }, [selectedScanId]);

  const changeEvent = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "test_type":
        setTestType(value);
        break;
      case "environment":
        setSelectedEnv(value);
        break;
      case "product_scan_id":
        setSelectedScanId(value);
        break;

      default:
        break;
    }
  };

  // Submit Fucion
  const onSubmit = (e: any) => {
    e.preventDefault();

    let target = e.target;
    let formData: any = {};

    for (let i = 0; i < target.length; i++) {
      if (target.elements[i].getAttribute("name") !== null) {
        if (["", "0"].includes(target.elements[i].value)) {
          setText(target.elements[i].getAttribute("name").replaceAll("_", " "));
          return;
        } else {
          formData[target.elements[i].getAttribute("name")] =
            target.elements[i].value;
        }
      }
    }
    let date: string[] = getDate() as string[];

    if (formData.certification_id === "Live") {
      formData.certification_id =
        formData.product_scan_id +
        "_" +
        date[1] +
        "-" +
        date[0] +
        "-" +
        date[2];
    } else if (formData.certification_id === "Lab") {
      formData.certification_id =
        "lab_" +
        formData.product_scan_id +
        "_" +
        date[1] +
        "-" +
        date[0] +
        "-" +
        date[2];
    }
    if (testType === "API Testing") {
      formData.certification_id = "API_" + formData.certification_id;
    }

    localStorage.setItem("testConditions", JSON.stringify(formData));
    setSubmit(true);
  };

  const getDate = () => {
    const today = Date.now();
    return new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
      .format(today)
      .split("/");
  };

  // Finalize Data
  const finalSubmit = (e: any) => {
    e.preventDefault();
    if (e.target.name === "ok") {
      testType === "Manual Testing" ? props.start() : setPage(7);
    } else {
      setSubmit(false);
    }
  };

  // ============================  HTML  ============================

  const formData = () => {
    return (
      <form className="bg-white w-100 px-3" onSubmit={onSubmit}>
        {preSelections("test_type", testTypes, changeEvent)}
        {testType === "Manual Testing" &&
          preSelections("environment", env, changeEvent)}
        {preSelections("product_scan_id", scanIds, changeEvent)}

        {/* Dropdowns */}
        {Object.keys(dropdowns).map((val) => selections(val, dropdowns[val]))}

        {inupts("no_of_images")}
        {testType === "Manual Testing" && inupts("lux_meter")}

        {!submit && (
          <>
            <p className="text-danger mb-3 text-center">{text} required</p>
            <button className="btn btn-primary mb-4" type="submit">
              Submit
            </button>
          </>
        )}
      </form>
    );
  };

  // Main
  return (
    <section>
      {/* Enno Text Logo */}
      <div className="text-center my-4">
        <img className="w-75" src="assets/images/intro/text_logo.svg" alt="" />
      </div>
      {dropdowns ? (
        <>
          {formData()}
          {submit && ConfirmTests(finalSubmit)}
        </>
      ) : (
        <h2 style={{ marginTop: "60%" }}>LOADING...</h2>
      )}
    </section>
  );
};

export default TestConditions;
