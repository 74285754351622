import Select from 'react-select'; 
const preSelections = (label: string, list: string[], changeEvent: any, isSearchable = false) => {
  if(isSearchable) {
    const options = list.map((item) => ({
      value: item,
      label: item.charAt(0).toUpperCase() + item.slice(1),
    }));
      return (
        <div key={label}>
      <label htmlFor={label}>{label.replaceAll("_", " ")}</label>
      <Select 
        onChange={((e: any)=>changeEvent(e))}
        options={options}
        isSearchable={true}
        isClearable={true}/>
        </div>
        )
  } else 
  return (
    <div key={label}>
      <label htmlFor={label}>{label.replaceAll("_", " ")}</label>
      <select
        name={label}
        id={label}
        className="form-control"
        onChange={changeEvent}
      >
        {list.map((el) => (
          <option value={el} key={el}>
            {el}
          </option>
        ))}
      </select>
    </div>
  );
};

const preSelections1 = (label: string, list: object, changeEvent: any) => {
  return (
    <div key={label}>
      <label htmlFor={label}>{label.replaceAll("_", " ")}</label>
      <select
        name={label}
        id={label}
        className="form-control"
        onChange={changeEvent}
      >
        {Object.entries(list).map(([key, value]) => (
          <option value={value} key={key}>
            {key}
          </option>
        ))}
      </select>
    </div>
  );
};

const selections = (label: string, list: string[]) => {
  return (
    <div key={label}>
      <label htmlFor={label}>{label.replaceAll("_", " ")}</label>
      <select name={label} id={label} className="form-control">
        {list.map((el) => (
          <option value={el} key={el}>
            {el}
          </option>
        ))}
      </select>
    </div>
  );
};

const inupts = (name: string) => {
  return (
    <>
      <label htmlFor={name}>{name}</label>
      <input id={name} name={name} className="form-control" type="number" />
    </>
  );
};

const inuptString = (name: string) => {
  return (
    <>
      <label htmlFor={name}>{name}</label>
      <input id={name} name={name} className="form-control" type="text" />
    </>
  );
};

export { preSelections, selections, inupts, inuptString, preSelections1};
