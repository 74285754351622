import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { detect } from 'detect-browser';
import API from '../../API/API';
import Axios from '../../Providers/Axios';
import { usePageContext } from '../../Providers/PageContext';
import Services from '../../Providers/Services';

// Pages
import {
  ApiTest,
  DeniedCam,
  LandingV1,
  NativeImg,
  Oops,
  OsSupport,
  ScanCam,
  TestConditionsV1,
  TestResult,
} from '../../Sharings';

const HomeV1 = () => {
  //  =============================  VARIABLES  ===============================
  const { t } = useTranslation(); // Translate

  const { page, setPage, img, setIsIos, captureType } = usePageContext(); // To change pages according to process
  const { getURL, getToken, getProductId, getSessionId, getHeaders } =
    Services(); // To store data in cookies
  const device: any = detect(); // To get device details

  const [deniedMsg, setDeniedMsg] = useState<string>(''); // To set Denied message status
  const camera = t('camera'); // On denied camera access
  const location = t('location'); // On denied loaction access

  const [totalCount, setTotalCount] = useState<number>(0); // Capture total count images
  const [productName, setProductName] = useState<string>(''); // To get product ID
  const [productId, setProductId] = useState<number>(0);
  const [overlay, setOverlay] = useState<string>('');
  const [captureCount, setCaptureCount] = useState<number>(1); // To show uploaded count
  const [camType, setCamType] = useState<string | null>(null); // webcam or native
  const [terminate, setTerminate] = useState<boolean>(false); // terminate auto capture loop

  const [endText, setEndText] = useState<string>('Align the product with logo'); // To change the captured count text

  const [startTime, setStartTime] = useState<string>(); // To get capture Start time
  const [endTime, setEndTime] = useState<string>(); // To get end time
  const [uploadTime, setUploadTime] = useState<any[]>([]); // To get each upload time

  const [osMsg, setOsMsg] = useState<number>(0); // To change os/browser support msg based on devices
  const [startUpload, setStartUpload] = useState<boolean>(false);

  // ==============================  FUNCTIONS  =================================

  // First step Process
  const stepCallFun = async () => {
    const testConditions = JSON.parse(
      localStorage.getItem('testConditions') || '{}'
    );
    setProductName(testConditions.product_scan_id);
    setTotalCount(testConditions.no_of_images);
    setCamType(testConditions.cam_type);
    getUserLocation();
  };

  useEffect(() => {
    if (productName && camType) {
      camType === 'Webcam' ? camHandler() : setPage(6);
    }
  }, [productName, camType]);

  useEffect(() => {
    if (productName) {
      (async () => {
        const res = (await getProductId(productName)) as any;
        setProductId(res[0]);
        setOverlay(res[1]);
      })();
    }
  }, [productName]);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
          (position: any) => {
            resolve({
              lat: position.coords.latitude,
              long: position.coords.longitude,
            });
          },
          (err: any) => {
            console.error(err);
            resolve(null);
            deniedFun(0);
          }
        );
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  // Cam Handler For IOS and Android
  const camHandler = () => {
    /* controls camera for different Devices */
    if (device.os === 'Android OS') {
      androidCam();
    } else if (device.os === 'iOS') {
      setIsIos(true);
      iosCam();
    } else {
      alert('Your OS does not support this site');
    }
  };

  // Android Browser, Cam check and page set
  const androidCam = () => {
    if (device.name === 'chrome') {
      setPage(1);
    } else {
      setOsMsg(2);
      setPage(4);
    }
  };

  // IOS Browser, OS, Cam check and page set
  const iosCam = () => {
    if (device.name === 'ios') {
      if (Number(device.version.slice(0, 2)) >= 11) {
        setPage(1);
      } else {
        setPage(4);
      }
    } else {
      setOsMsg(1);
      setPage(4);
    }
  };

  //  ==============================  API'S  =================================

  // Start upload
  useEffect(() => {
    if (img) { 
      setStartUpload(true);
    }
  }, [img]);

  // Get Session ID, Upload Image and Verify
  useEffect(() => {
    if (img && terminate === false) {
      setEndText(`uploading ${captureCount}/${totalCount}`);
      !startTime && setStartTime(getCurrentTime);
      const imgFile = dataURLtoFile(img);
      (async () => {
        const geoLoc = await getUserLocation();
        const sessionId = (await getSessionId(
          geoLoc,
          productId,
          device
        )) as string;
        if (sessionId) {
          const uploadStatus = (await uploadImg(imgFile, sessionId)) as string;
          const teleportImageData = await teleportImage(imgFile, sessionId) ;
          createMetaData(teleportImageData?.data.id,
            teleportImageData?.data.originalFilename,
            teleportImageData?._links
          );
          if (uploadStatus) {
            if (uploadStatus === 'OK') {
              setEndText(`uploaded ${captureCount}/${totalCount}`);
              if (captureCount + 1 <= totalCount) {
                await getToken();
                setCaptureCount((prev) => prev + 1);
                if(captureType === 'manual'){
                  setTerminate(true);//break from automatic loop
                  setStartUpload(false);
                }
              } else {
                setEndText('Done');
                setEndTime(getCurrentTime);
                setTimeout(() => {
                  setPage(2);
                }, 1000);
              }
            } else {
              setEndText(`failed to upload ${captureCount}`);
            }
          }
        }
      })();
    } else {
      setTerminate(false);//reset flag for next iteration triggered on 
    }
  }, [startUpload, captureCount]);

  const getCurrentTime = () => {
    let date = new Date();
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  // Convert DataURL to File
  const dataURLtoFile = (dataurl: any) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], 'capture.jpg', { type: mime });
  };

  // Upload Image to AWS blob
  const uploadImg = (imgFile: any, sessionId: string) => {
    const start = Date.now();
    const formData = new FormData();
    formData.append('input_image', imgFile);
    return new Promise((resolve) => {
      Axios.post(getURL() + API.uploadImg + sessionId, formData, getHeaders())
        .then((res) => {
          const end = Date.now();
          const responseTime = end - start;
          setUploadTime([
            ...uploadTime,
            { session: sessionId, time: responseTime },
          ]);
          resolve(res.data.msg);
        })
        .catch((err) => {
          console.error(err);
          setPage(5);
        });
    });
  };

  async function teleportImage(imgFile: File, sessionId: string){
    try {
        const fileData = await imgFile.arrayBuffer(); // Convert File to ArrayBuffer
        const binaryData = new Uint8Array(fileData);
        const updatedFileName = `${imgFile.name.split(".")[0]}_${sessionId}.${imgFile.name.split(".").pop()}`;
        const headers = {
          'Authorization': 'Basic bWxvcHM6QWcyejAxY2duT1VPSG01bjBLN0ZIeVJkeEFMc0dndFNQWWVTVjQySlZaaw==',
          'x-upload-sink': 'ennoventure-teleport',
          'Content-Type': 'application/octet-stream',
          'x-root-path': 'sample',
          'x-relative-path': '/test',
          'x-original-filename': updatedFileName
        };
        const response = await Axios.post(API.teleportImage, binaryData, { headers });
        console.log('response', response)
        return response.data;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error; 
    }
  }

  const createMetaData = async (
    imageId: string = "",
    imageName: string = "",
    links: object = {}

): Promise<void> => {
  const testConditions =  {...JSON.parse(localStorage.getItem('testConditions') || '{}')};
  const additionalInfo = {...JSON.parse(localStorage.getItem('testConditions') || '{}')};
  delete additionalInfo['product_scan_id'];
  delete additionalInfo['decoderConfigId'];
  delete additionalInfo['image_tag'];
  delete additionalInfo['test_case'];
  delete additionalInfo['imageTag'];
  console.log('1122121',testConditions, testConditions.project);
  let formattedLabel = testConditions?.test_case?.toLowerCase();
  formattedLabel = ["copy prints", "scan prints"].includes(formattedLabel) ? 'copies' : formattedLabel;
    const requestBody = {
        data: {
            entityType: 'UPLOAD',
            entityId: imageId,
            type: 'static',
            source: 'test-vyu-app',
            context: {
                imageId,
                imageTag: testConditions.image_tag,
                decoderConfigId: testConditions.decoderConfigId,
                scanId: testConditions.product_scan_id,
                imageSource: 'test-vyu-app',
                projectId: testConditions.project,
                imageName: imageName,
                classLabel: [{
                  taskId:"",
                  label: formattedLabel,
                }],
                additional_info: {
                  ...additionalInfo
                },
                links,
            },
        },
    };

    try {
        const response = await Axios.post(API.createMeta, requestBody, {
            headers: {
              'Authorization': 'Basic bWxvcHM6QWcyejAxY2duT1VPSG01bjBLN0ZIeVJkeEFMc0dndFNQWWVTVjQySlZaaw==',
              'Content-Type': 'application/json',
            },
        });
        console.log('Meta created successfully:', response.data);
    } catch (error) {
        console.error('Error creating meta:', error);
        throw error;
    }
};
  // ==============================  FAIL CACES  ==============================

  // Denied Location and cam access
  const deniedFun = (id: number) => {
    id ? setDeniedMsg(camera) : setDeniedMsg(location);
    setPage(3);
  };

  // ===============================  HTML  ================================

  // Main
  return (
    <div>
      {page === -1 && <LandingV1 />}
      {page === 0 && <TestConditionsV1 start={stepCallFun} />}
      {page === 1 && (
        <ScanCam
          overlay={overlay}
          productName={productName}
          denied={() => deniedFun(1)}
          captureCount={captureCount}
          endText={endText}
        />
      )}
      {page === 2 && (
        <TestResult
          startTime={startTime}
          endTime={endTime}
          uploadTime={uploadTime}
        />
      )}
      {page === 3 && <DeniedCam deniedMsg={deniedMsg} />}
      {page === 4 && <OsSupport msg={osMsg} />}
      {page === 5 && <Oops />}

      {/* Capture Image from native cam */}
      {page === 6 && <NativeImg productName={productName} endText={endText} />}
      {page === 7 && <ApiTest />}
    </div>
  );
};

export default HomeV1;
