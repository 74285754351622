import { BrowserRouter as Router, Routes, Route, Navigate,   createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home } from "./Pages";
import HomeV1 from "./Pages/HomeV1/Home";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { Suspense } from "react";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "assets/i18n/{{lng}}/translate.json",
    },
  });

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Home />
      ),
    },
  {
      path: "/v1", 
      element: (
        <HomeV1 />
      ),
    },
    {
      path: "*", 
      element: (
        <Home />
      ),
    },
  ]);

  function App(){
    return <RouterProvider router={router} />
  }

  export default App;
