const API = {
  token: "auth/login",
  fetchAllScanIds: "fetch-all-scanids",
  fetchProdId: "fetch-experience?product_scan_id=",
  session: "verify/start-session",
  uploadImg: "verify/upload-image?session_id=",
  decode: "verify/session-result?session_id=",
  teleportImage: "https://teleport-dev.ops.enncrypto.com/api/teleport-service/v1/upload/uploadFile",
  createMeta: "https://meta-service-dev.ops.enncrypto.com/api/metaservice/v1/meta/createMeta",
};

export default API;
